import { makeAutoObservable } from "mobx";
import { IUser } from "../components/UserData";
import { apiHandler } from "../API/apiHandler";
import { progressStateEnum } from "../enums/progressStateEnum";

class UserStore {
    public user: IUser[] | null;
    public allUsers: IUser[] | null;
    public progressState: progressStateEnum;
    public originalEmail: string | null;
    public copyEmail: string | null;
    public currentSubscription: IUser | null;
    public currentSubscriptionIndex: number | null
    public updateAmount: number;

    constructor() {
        makeAutoObservable(this);
        this.user = [];
        this.allUsers = [];
        this.progressState = progressStateEnum.LoginPage
        this.originalEmail = null
        this.copyEmail = null
        this.currentSubscription = null;
        this.currentSubscriptionIndex = null;
        this.updateAmount = 0;
    }

    setProgressState = (newProgress: progressStateEnum) => {
        this.progressState = newProgress
    }

    setUser = (newUsers:IUser[]) => {
        this.user = newUsers;
    }

    sendVerificationMail = async (email: string, isUpdated = false) => {
        if(isUpdated && this.originalEmail) this.copyEmail = email;
        else this.originalEmail = email;
        
        const res = await apiHandler.sendVerificationMail(this.copyEmail ?? this.originalEmail, isUpdated);
        if (!res || res === "User not exist") return alert("משתמש לא קיים");
        if (res === "All users updated already") return alert("כל המתמשים עודכנו עבור לקוח זה");
        else this.progressState = progressStateEnum.EmailVerificationPage;
    }
    
    checkVerificationCode = async (code: number, isUpdated = false) => {
        if(!this.originalEmail) return alert('error')

        isUpdated = this.updateAmount !== 0 ? true : false
        const res = await apiHandler.checkVerificationCode(this.copyEmail ?? this.originalEmail, code, isUpdated);
        if(!res) return 'numError';
        
        return await this.checkValidUser(this.originalEmail)
        .then(()=>this.progressState = progressStateEnum.ProfilePage)
        .catch((err) =>this.progressState = progressStateEnum.LoginPage )
    }

    checkValidUser = async (email: string) => {
        const res = await apiHandler.getUserDataByEmail(email);
        if (!res) return alert("משתמש לא קיים");
        this.allUsers = res;
        this.user = res.filter((sub:IUser) => sub.isUpdated === false);

        return this.user;
    };

    chooseSubscription = (subscription:IUser, index:number) => {
        this.currentSubscription = subscription;
        this.currentSubscriptionIndex = index;
        this.setProgressState(progressStateEnum.EditPage)
    }

    setUpdated = () => {
        this.updateAmount++;
    }

    isOwnerAlready = () => {
        return this.allUsers?.some(user => user.relation === "owner");
    }

    updateAllUsers = (userUpdate: IUser) => {
        const indexToUpdate = this.allUsers?.findIndex(user => user.customerNumber === userUpdate.customerNumber);
        if (indexToUpdate != null && indexToUpdate !== -1 && this.allUsers) {
            let updatedUsersArray = [...this.allUsers];
            updatedUsersArray[indexToUpdate] = userUpdate
            this.allUsers = updatedUsersArray;
        }

        return this.allUsers?.some(user => user.relation === "owner");
    }

    // // FUNCTION FOR TESTING
    // loadFakeData = () => {
    //     this.user = [...fakeTempData];
    // };
    // // FUNCTION FOR TESTING
}

export default new UserStore();
