import userStore from "../../../store/userStore";
import { ContentSqaure } from "../../UI/ContentSqaure";
import { SubscriptionSquare } from "../../UI/SubscriptionSquare";
import { ReactComponent as Family } from "../../../assets/relation/family.svg";
import { ReactComponent as FamilyFilled } from "../../../assets/relation/family_filled.svg";
import { ReactComponent as Marriage } from "../../../assets/relation/marriage.svg";
import { ReactComponent as MarriageFilled } from "../../../assets/relation/marriage_fiiled.svg";
import { ReactComponent as Misc } from "../../../assets/relation/misc.svg";
import { ReactComponent as MiscFilled } from "../../../assets/relation/misc_filled.svg";
import styles from "./EditStyles.module.scss";
import { FormEvent, useEffect, useState } from "react";
import { FormInput } from "./FormInput";
import { IUser } from "../../UserData";
import { apiHandler } from "../../../API/apiHandler";
import { progressStateEnum } from "../../../enums/progressStateEnum";
import { observer } from "mobx-react";
import { Loader } from "../../UI/Loader";

interface IErrors {
    email: string | boolean;
    id: string | boolean;
    relation: string | boolean;
    firstName: string | boolean;
    lastName: string | boolean;
    phoneNumber: string | boolean;
    city: string | boolean;
    address: string | boolean;
    addressNum: string | boolean;
    date: string | boolean;
}

const Edit = () => {
    const { currentSubscription, currentSubscriptionIndex, user, chooseSubscription, setProgressState, setUpdated, sendVerificationMail, isOwnerAlready, updateAllUsers } = userStore;
    const [relations, setRelations] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [id, setId] = useState<number>();
    const [date, setDate] = useState<string>();
    const [city, setCity] = useState<string>();
    const [address, setAddress] = useState<string>();
    const [addressNum, setAddressNum] = useState<number>();
    const [permission, setPermission] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOverEighteen, setIsOverEighteen] = useState<boolean>(false);
    const [errors, setErrors] = useState<IErrors>({
        email: false,
        id: false,
        relation: false,
        firstName: false,
        lastName: false,
        phoneNumber: false,
        city: false,
        address: false,
        date: false,
        addressNum: false,
    });

    const [showPopup, setShowPopup] = useState<boolean>(false);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRelations(event.target.value);
    };

    const handleDate = (dateOfBirth: string) => {
        setDate(dateOfBirth);
        if(checkIfOverEighteen(dateOfBirth)){
            setIsOverEighteen(true);
            relations === "family" && setRelations(undefined);
        } else {
            setIsOverEighteen(false);
        }
    };

    const checkIfOverEighteen = (dateOfBirth: string) => {
        const birthDate = new Date(dateOfBirth);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
      
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age = age - 1;
        }
        return age >= 18;
      };

    function validate(e: FormEvent) {
        e.preventDefault();

        let temp: IErrors = {
            email: false,
            id: false,
            relation: false,
            firstName: false,
            lastName: false,
            phoneNumber: false,
            city: false,
            address: false,
            date: false,
            addressNum: false,
        };

        // First & Last Name Validator
        const hebrewRegex = /^[\u0590-\u05FFa-zA-Z\s]+$/;
        if (!firstName?.match(hebrewRegex)) temp = { ...temp, firstName: "אנא הזינו אותיות בלבד" };
        if (!lastName?.match(hebrewRegex)) temp = { ...temp, lastName: "אנא הזינו אותיות בלבד" };
        // First & Last Name Validator

        // Phone Number Validator
        const phoneNumberRegex = /^\d{10}$/;
        if (!phoneNumber?.match(phoneNumberRegex)) temp = { ...temp, phoneNumber: "אנא הזינו 10 ספרות בלבד" };
        // Phone Number Validator

        // Email Validator
        const emailRegex = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
        if (!email || !email?.match(emailRegex)) temp = { ...temp, email: "אנא הזינו כתובת תקינה" };
        if(email?.indexOf("+") !== -1) temp = { ...temp, email: "אין להקליד '+' בכתובת המייל" }
        // Email Validator

        // ID Validator
        const idRegex = /^\d{9}$/;
        if (!`${id}`?.match(idRegex)) temp = { ...temp, id: "אנא הזינו 9 ספרות בלבד" };
        // ID Validator

        // Date Validator
        if (!date) temp = { ...temp, date: "אנא הזן תאריך לידה" };
        // Date Validator

        // City Validator
        if (!city?.match(hebrewRegex)) temp = { ...temp, city: "אנא הזינו אותיות בלבד" };
        // City Validator

        // Address Validator
        const addressRegex = /^(?=.*[\u0590-\u05FF]).+$/;

        if (!address?.match(addressRegex)) temp = { ...temp, address: "אנא מלאו את שם הרחוב והמספר הבית" };
        // Address Validator

        // Relation Validator
        if (typeof relations !== "string") temp = { ...temp, relation: "אנא סמנו קרבה" };
        // Relation Validator

        const hasError = Object.keys(errors).some((error) => typeof temp[error as keyof IErrors] === "string");

        setErrors(temp);
        if (hasError) {
            setIsLoading(false)
            return;
        }
        if (relations === "misc") {
            togglePopup();
        } else {
            goToNextPage();
        }
    }

    const togglePopup = () => {
        if (showPopup) {
            setShowPopup(false);
            document.body.style.overflow = "auto";
        } else {
            setShowPopup(true);
            document.body.style.overflow = "hidden";
        }
    };
    useEffect(()=>{
        setShowPopup(false);
        document.body.style.overflow = "auto";
        if(!isOwnerAlready()) setRelations("owner");
    },[])

    async function goToNextPage() {
        setIsLoading(true)
        if (user && currentSubscriptionIndex !== null && currentSubscription  && email && id && relations && firstName && lastName && phoneNumber && city && address && date && addressNum) {
            let newUser: IUser = {
                ...currentSubscription,
                email: email,
                id: id,
                relation: relations,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: +phoneNumber,
                addressCity: city,
                addressStreet: address,
                addressNumber: addressNum,
                dateOfBirth: date,
                isUpdated: true,
                OwnerRights: permission ?? false,
            };
            user[currentSubscriptionIndex].isUpdated = true;
            try {
                try{
                    if(relations === "misc") newUser.OwnerRights = true;
                    const resSave = await apiHandler.saveUser(newUser);
                    if(resSave === "Updated successfully") updateAllUsers(newUser);
                } catch(err){
                    let errMessage;
                    switch ((err as any).response.data) {
                        case "Phone number already exist":
                            errMessage = "מספר הטלפון כבר קיים במערכת"
                            break;
                        case "Email already exist":
                            errMessage = "המייל כבר קיים במערכת"
                            break;
                        case "First Name already exist":
                            errMessage = "שם פרטי זה כבר קיים עבור הלקוח"
                            break;
                        case "Id already exist":
                            errMessage = "תעודת זהות זו כבר קיימת במערכת"
                            break;
                    }
                    if (showPopup) {
                        setShowPopup(false);
                        document.body.style.overflow = "auto";
                    }
                    setIsLoading(false);
                    return alert(errMessage ?? (err as any).response.data);
                }
                setUpdated();
                const nextSubscription: IUser | undefined = user?.find((user) => user.isUpdated === false);

                if (relations === "misc") {
                    await sendVerificationMail(email, true);
                    return setProgressState(progressStateEnum.EmailVerificationPage);
                }
                if (!nextSubscription) return setProgressState(progressStateEnum.DonePage);
                chooseSubscription(nextSubscription, currentSubscriptionIndex + 1);
                if (showPopup) {
                    setShowPopup(false);
                    document.body.style.overflow = "auto";
                }
                setProgressState(progressStateEnum.ProfilePage);
            } catch {
                setIsLoading(false)
                alert("אירעה שגיאה בעדכון המנוי, אנא נסה שוב");
            }
        }
    }

    return (
        <ContentSqaure>
            {currentSubscription && (
                <div style={{ width: "100%" }}>
                    <SubscriptionSquare subscription={currentSubscription} />
                    <form onSubmit={validate} className={styles["edit-subscription-class"]}>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBlock: "1rem 2rem", flexDirection: "column" }}>
                            <label className="pointer" htmlFor="permissions" style={{ fontSize: "15px", lineHeight: "18px", paddingBottom: "5px" }}>
                                במשחקים בהם יש מכירה מוגבלת בהתאם לכמות מנויים, מה הבחירה שלכם ?
                            </label>
                            {/* <input id="permissions" name="permissions" type="checkbox" onChange={(e) => setPermission(e.target.checked)} style={{ display: "inline", width: "auto" }} /> */}
                            <div style={{display: "inline-grid", "direction": "rtl"}}>
                                <div style={{display: "inline-flex", alignItems: "center"}}>
                                    <input style={{"width": "30px"}} defaultChecked={true} id="permission-no" name="permissions" type="radio" value="no" onChange={(e) => setPermission(false)}/>
                                    <label style={{"width": "100%"}} htmlFor="permission-yes">המנוי הראשי זכאי לרכוש את הכרטיסים ומנוי זה לא זכאי.</label>
                                </div>
                                <div style={{display: "inline-flex", alignItems: "center"}}>
                                    <input style={{"width": "30px"}} id="permission-yes" name="permissions" type="radio" value="yes" onChange={(e) => setPermission(true)}/>
                                    <label style={{"width": "100%"}} htmlFor="permission-no">מנוי זה זכאי לרכוש כרטיס בודד.</label>
                                </div>
                            </div>
                        </div>

                        <FormInput name="firstName" text="שם פרטי" errorMessage={(errors.firstName as string) ?? undefined} onChangeFunction={setFirstName} dir="rtl" />
                        <FormInput name="lastName" text="שם משפחה" errorMessage={(errors.lastName as string) ?? undefined} onChangeFunction={setLastName} dir="rtl" />
                        <FormInput name="phoneNumber" type="number" text="מספר טלפון" errorMessage={(errors.phoneNumber as string) ?? undefined} onChangeFunction={setPhoneNumber} />
                        <FormInput name="email" type="email" text='כתובת דוא"ל' errorMessage={(errors.email as string) ?? undefined} onChangeFunction={setEmail} />
                        <FormInput name="id" type="number" text="תעודת זהות" errorMessage={(errors.id as string) ?? undefined} onChangeFunction={setId} />
                        <FormInput name="birthDate" type="date" text="תאריך לידה" errorMessage={(errors.date as string) ?? undefined} onChangeFunction={handleDate} max={new Date()}/>
                        <FormInput name="city" text="עיר" errorMessage={(errors.city as string) ?? undefined} onChangeFunction={setCity} dir="rtl" />
                        <FormInput name="address" text="רחוב" errorMessage={(errors.address as string) ?? undefined} onChangeFunction={setAddress} dir="rtl" />
                        <FormInput name="addressNum" type="number" text="מספר" errorMessage={(errors.addressNum as string) ?? undefined} onChangeFunction={setAddressNum} dir="rtl" />

                        {isOwnerAlready() &&
                        <div>
                            <label htmlFor="relations">קירבה{errors.relation && <span className="error-validator">{errors.relation}</span>}</label>
                            <div className={`${styles["relations-container"]} ${isOverEighteen ? styles["over-eighteen-true"] : ""}`}>
                                <div className={styles["relation"]}>
                                    <label className="pointer" htmlFor="misc">
                                        {relations === "misc" ? <MiscFilled /> : <Misc />}
                                        <input type="radio" id="misc" name="relations" value="misc" checked={relations === "misc"} onChange={handleOptionChange} />
                                        <div>אחר</div>
                                    </label>
                                </div>

                                { !isOverEighteen &&
                                    <div className={styles["relation"]}>
                                        <label className="pointer" htmlFor="family">
                                            {relations === "family" ? <FamilyFilled /> : <Family />}
                                            <input type="radio" id="family" name="relations" value="family" checked={relations === "family"} onChange={handleOptionChange} />
                                            <div>בן/בת עד גיל 18</div>
                                        </label>
                                    </div>
                                }

                                <div className={styles["relation"]}>
                                    <label className="pointer" htmlFor="marriage">
                                        {relations === "marriage" ? <MarriageFilled /> : <Marriage />}
                                        <input type="radio" id="marriage" name="relations" value="marriage" checked={relations === "marriage"} onChange={handleOptionChange} />
                                        <div>בעל/אישה</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        }
                        <button className={styles["form-button"]}>המשך</button>
                    </form>
                    {showPopup && (
                        <div className={styles["popup"]}>
                            <div className={styles["popup__container"]}>
                                <button className={styles["popup__close"]} onClick={togglePopup}>
                                    X
                                </button>
                                <div className={styles["popup__content"]}>
                                    <h3 style={{ width: "70%", margin: "auto", direction: "rtl", marginBottom: "2rem" }}>שים לב בחרת קירבה מסוג "אחר"</h3>
                                    <p>פעולה זו גורמת להעברת מנוי למי שאינו מקרבה ראשונה, יבוצע אימות מול כתובת הדוא”ל שהוזנה והמנוי וזכויות רכישת כרטיס למשחק חוץ יעבור אליו.</p>
                                    <div style={{height:"2rem", marginTop:"1rem"}}>{isLoading && <Loader/>}</div>
                                    <button style={{ marginTop: "2rem" }} onClick={goToNextPage}>
                                        המשך
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </ContentSqaure>
    );
};

const EditObserver = observer(Edit);
export default EditObserver;
