import { PropsWithChildren } from "react";
import styles from "./ContentSqaure.module.scss";

interface IProps {
    children: React.ReactNode
}

export const ContentSqaure:React.FC<IProps> = ({children}) => {
  return (
    <div className={styles["content-square"]}>{children}</div>
  )
}