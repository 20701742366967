import React, { useMemo, useState } from 'react';
import './App.scss';
import Login from './components/pages/Login/Login';
import Done from './components/pages/Done/Done';
import Profile from './components/pages/Profile/Profile';
import EmailVerification from './components/pages/EmailVerification/EmailVerification';
import HBSFC_logo from "./assets/HBSFC_logo.png"
import { progressStateEnum } from './enums/progressStateEnum';
import userStore from './store/userStore';
import { observer } from 'mobx-react';
import Edit from './components/pages/Edit/Edit';

const App: React.FC = () => {
  const {progressState, setProgressState} = userStore
  
  const currentScreen = useMemo(()=> {
    switch (progressState) {
      case progressStateEnum.LoginPage:
        return <Login />
      case progressStateEnum.EmailVerificationPage:
        return <EmailVerification />
        case progressStateEnum.ProfilePage:
        return <Profile />
        case progressStateEnum.EditPage:
        return <Edit />
        case progressStateEnum.DonePage:
        return <Done />

      default:
        return <Login />
    }
  },[progressState])

  return (
      <div className="page-layout">
        <img className='HBSFC-logo' src={HBSFC_logo}/>
        {currentScreen}
        {/* <button onClick={()=> setProgressState(progressStateEnum.LoginPage)}>login</button> */}
        {/* <button onClick={()=> setProgressState(progressStateEnum.ProfilePage)}>profile</button> */}
        {/* <button onClick={()=> setProgressState(progressStateEnum.DonePage)}>done</button> */}
          {/* <UserData /> */}
      </div>
  );
};

const AppObserver = observer(App)
export default AppObserver;