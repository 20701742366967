import axios from "axios";
import { IUser } from "../components/UserData";

class ApiHandler {
    public url: string;
    constructor() {
        // this.url = "http://localhost:4000/api/users"
        this.url = "https://users-server.eventimsport.com/api/users"
    }
    
    async sendVerificationMail(userEmail: string, isUpdated: boolean){
        return await axios.post<any>(this.url + '/generateAuthenticateCodeEmail', { "userEmail": userEmail, "isUpdated":isUpdated })
        .then(response => response.data)
        .catch(error => console.error('There was an error sending the email!', error));
    }
    
    async checkVerificationCode(userEmail: string, emailCode: number, isUpdated: boolean){
        return await axios.post<any>(this.url + '/validateAuthenticateCodeEmail', { userEmail, emailCode, isUpdated })
        .then(response => response.data)
    }
    
    async getUserDataByEmail(userEmail: string){
        return await axios.get<any>(this.url + '/getUserByEmail', { params: { userEmail } })
        .then(response => response.data)
        .catch(error => console.error('There was an error sending the email!', error));
    }
    
    async saveUser(user: IUser){
        return await axios.post<any>(this.url + '/validateSave', { user })
        .then(response => response.data)
    }
}

export const apiHandler = new ApiHandler()