import { FormEvent, useMemo, useState } from "react"
import { observer } from "mobx-react"
import userStore from "../../../store/userStore"
import { ContentSqaure } from "../../UI/ContentSqaure"
import { Loader } from "../../UI/Loader"


interface IErrors {
  email?: string | null
}

const Login:React.FC = () => {
  const {sendVerificationMail} = userStore
  const [errors, setErrors] = useState<IErrors>({
    email: null
  })
  const [email, setEmail] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const isValid = useMemo(()=>{
    const emailRegex = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

    if(email.match(emailRegex)) return true
    if(email?.indexOf("+") !== -1) setErrors({...errors, email: "אין להקליד '+' בכתובת המייל"})
    return false;
  },[email])

  async function handleSubmit(e:FormEvent) {
    e.preventDefault();
    setLoading(true)
    await sendVerificationMail(email)
    setLoading(false)
  }

  return (
      <ContentSqaure>
        <h3>אוהדים יקרים עדכון הפרטים הסתיים תודה על שיתוף הפעולה</h3>
        {/* <p style={{direction: "rtl"}}>ישנם מספר מנויים רשומים על כתובת מייל זו, ראשית אנא בחרו את המנוי הראשי (אליו יקושרו המנויים) והזינו עבורו את הפרטים. <br/> חידוש המנויים יהיה זמין בעוד 48 שעות.</p> */}
        {/* <p style={{direction: "rtl", width: "90%", textAlign: "right"}}>אוהדים יקרים, החל מעונת המנויים הקרובה לא ניתן יהיה להחזיק יותר ממנוי אחד על אותו שם ועל אותה כתובת אימייל. <br/> בקישור הבא תתבקשו לעדכן את הפרטים האישיים של כל מנוי. <br/>נא וודאו מראש כי יש ברשותכם את כל הפרטים הדרושים (שם מלא, אימייל שונה לכל מנוי, מספר טלפון ומספר ת״ז) <br/>חידוש המנויים יתאפשר 48 שעות לאחר סיום תהליך העדכון.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">כתובת דוא"ל של בעל המנוי, שדה חובה{errors?.email && <span className="error-validator">{errors.email}</span>}</label>
          <input type="email" name="email" onChange={(e) => setEmail(e.target.value)}/>
          <div style={{height:"2rem", marginTop:"1rem"}}>{loading && <Loader/>}</div>
          <button style={{margin: "2rem", paddingInline: "3rem"}} disabled={!isValid || loading}>שליחת קוד לדוא"ל</button>
        </form> */}
        {/* <button onClick={loadFakeData}>Load Fake Data</button> */}
      </ContentSqaure>
  )
}

const LoginObserver = observer(Login)
export default LoginObserver;