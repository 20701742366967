import { observer } from "mobx-react";
import { ContentSqaure } from "../../UI/ContentSqaure";
import userStore from "../../../store/userStore";
import { FormEvent, useMemo, useRef, useState } from "react";
import styles from "./EmailVerification.module.scss";
import { Loader } from "../../UI/Loader";

interface IErrors {
  verification: string | null;
}

const EmailVerification: React.FC = () => {
    const [numInput, setNumInput] = useState<string[]>(Array(6).fill(''));
    const { originalEmail, copyEmail, checkVerificationCode } = userStore;
    const [loading, setLoading] = useState<boolean>(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const [errors, setErrors] = useState<IErrors>({
      verification: null
    })
    const isFilled = useMemo(()=>{
      return numInput.findIndex(num => num === "" || num === undefined) !== -1
    },[numInput])

    function updateNumber(cell: number, num: string) {
        if (!/^[0-9]*$/.test(num)) return;

        let temp: string[] = [...numInput];
        function getNewNumber(oldNum: string, targetNum: string): number {
            if (oldNum !== "") return targetNum.indexOf(oldNum) == 0 ? 1 : 0;
            else return 0;
        }
        let index: number = getNewNumber(temp[cell], num);
        temp[cell] = num[index];
        setNumInput(temp);
        
        if (containerRef.current && cell < containerRef.current.children.length - 1) {
            let nextEle = containerRef.current?.children[cell + 1] as HTMLInputElement;
            nextEle.focus();
        }
    }


    async function handleSubmit(e:FormEvent) {
      e.preventDefault();
      const searchEmpty = numInput.findIndex(num => num === "");
      
      if(numInput.length !== 6 || searchEmpty !== -1 ) return alert('יש למלא את כל המספרים')
      const concatenatedNumber = Number(numInput.join(''));
      setLoading(true)
      const res = await checkVerificationCode(concatenatedNumber)
      if(res === "numError") {
        setLoading(false)
        setErrors({...errors, verification: "קוד אינו תואם"})
        setNumInput(Array(6).fill(''))
      }
      
      
    }    
    
    return (
        <ContentSqaure>
            <div style={{fontWeight:"bold"}}>נשלח קוד לכתובת המייל</div>
            <div>{copyEmail ?? originalEmail}</div>
            <h4 style={{marginBottom: "0"}}>הזינו בבקשה את הקוד כאן</h4>
            <div style={{marginBottom: "1rem", height:"1rem"}}>{errors?.verification && <span className="error-validator">{errors.verification}</span>}</div>
            <form style={{margin:"auto", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}} onSubmit={handleSubmit}>
              <div ref={containerRef} style={{display:"flex", maxWidth: "80%"}}>
              {numInput.map((num, index) => (
                  <input
                    key={index}
                    className={styles["number-input"]}
                    type="number"
                    // maxLength={1}
                    value={num}
                    onChange={(e) => updateNumber(index, e.target.value)}
                  />
                ))}
              </div>
              <div style={{height:"2rem", marginTop:"1rem"}}>{loading && <Loader/>}</div>
              <button disabled={isFilled || loading} style={{marginTop:"2rem"}}>המשך</button>
            </form>
        </ContentSqaure>
    );
};

const EmailVerificationObserver = observer(EmailVerification);
export default EmailVerificationObserver;
