import { useEffect, useState } from "react";
import userStore from "../../../store/userStore";
import styles from "./ProfileStyles.module.scss";
import SubscriptionListItem from "./SubscriptionList";
import { observer } from "mobx-react";
import { progressStateEnum } from "../../../enums/progressStateEnum";
import { IUser } from "../../UserData";
import { ContentSqaure } from "../../UI/ContentSqaure";
import { toJS } from "mobx";

const Profile: React.FC = () => {
    const { user, setProgressState, setUser, updateAmount, isOwnerAlready } = userStore;
    const [selectedSubscription, setSelectedSubscription] = useState<IUser>();
    const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState<number>();
    const [nonUpdatedSubscriptions, setNonUpdatedSubscriptions] = useState<IUser[]>([])

    useEffect(() => {
        if (!user) return setProgressState(progressStateEnum.LoginPage)
        let isEveryThingUpdated = user.findIndex(subscription => subscription.isUpdated === false) === -1
        if (isEveryThingUpdated && updateAmount !== 0) setProgressState(progressStateEnum.DonePage);
        if (isEveryThingUpdated && updateAmount === 0) setProgressState(progressStateEnum.LoginPage);
        else {
            let temp: IUser[] = [...user].filter(sub => sub.isUpdated === false)
            setNonUpdatedSubscriptions(temp)
            setUser(temp)
        }
    }, []);
    const handleSubscriptionChange = (subscription:IUser, index: number) => {
        setSelectedSubscription(subscription);
        setSelectedSubscriptionIndex(index)
    };

    const handleContinue = () => {        
        if (selectedSubscription && selectedSubscriptionIndex!== undefined) {
            userStore.chooseSubscription(selectedSubscription, selectedSubscriptionIndex);
        }
    };

    return (
        <ContentSqaure>
            {nonUpdatedSubscriptions?.length && (
                <div>
                    {!isOwnerAlready() && <p style={{fontWeight: "bold"}}>ישנם מספר מנויים רשומים על כתובת מייל זו, אנא בחרו את המנוי הראשי - בעל המנוי והזינו עבורו את הפרטים</p>}
                    {isOwnerAlready() && <p style={{fontWeight: "bold"}}>תודה רבה הפרטים נשמרו , אנא מלאו את הפרטים עבור המנוי הבא</p>}
                    <div style={{ width: "300px", margin: "auto" }}>
                        <ul className={styles["subscription-list"]}>
                            {nonUpdatedSubscriptions?.map((subscription, index) => (
                                <SubscriptionListItem
                                    subscription={subscription}
                                    index={index}
                                    key={index}
                                    onSubscriptionChange={handleSubscriptionChange}
                                />
                            ))}
                        </ul>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <button
                            onClick={handleContinue}
                            disabled={!selectedSubscription}
                            className={styles["continue-button"]}
                        >
                            המשך
                        </button>
                    </div>
                </div>
            )}
        </ContentSqaure>
    );
};
const ProfileObserver = observer(Profile)
export default ProfileObserver;