import React from 'react';
import styles from './ProfileStyles.module.scss';
import { IUser } from '../../UserData';
import { observer } from 'mobx-react';
import { SubscriptionSquare } from '../../UI/SubscriptionSquare';

interface IProps {
    subscription: IUser;
    index: number;
    onSubscriptionChange: (subscription: IUser, index: number) => void;
}

const SubscriptionListItem: React.FC<IProps> = ({ subscription, index, onSubscriptionChange }) => {
    const handleChange = () => {
        onSubscriptionChange(subscription, index);
    };

    return (
        <li className={styles["subscription-list-item"]}>
        <label className='pointer' htmlFor={`subscription-${index}`}>
            <input
                type="radio"
                name="subscription"
                className={styles["subscription-list-item__button"]}
                onChange={handleChange}
                id={`subscription-${index}`}
            />
        <SubscriptionSquare subscription={subscription}/>
        </label>
        </li>
    );
};

const SubscriptionListObserver = observer(SubscriptionListItem);
export default SubscriptionListObserver;
