import { HTMLInputTypeAttribute } from "react";

interface IProps {
    onChangeFunction: React.Dispatch<React.SetStateAction<any>>,
    errorMessage?: string,
    type?: HTMLInputTypeAttribute,
    name: string,
    text: string,
    dir?: "ltr" | "rtl",
    max?: Date,
}

const formatDate = (date: Date | undefined) => {
    if(date){
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0');
        const day = `${d.getDate() - 1}`.padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    }
  };

export const FormInput:React.FC<IProps> = ({onChangeFunction, errorMessage, name, text, type, dir, max}) => {
    return (
        <div className="input-container">
            <label style={{fontSize: "12px"}} htmlFor={name}>{text}{errorMessage && <span className="error-validator">{errorMessage}</span>}</label>
            <input style={{direction: dir}} type={type ?? "text"} name={name} onChange={(e) => onChangeFunction(e.target.value)} max={type === 'date' ? formatDate(max) : ""}/>
        </div>
    );
};
