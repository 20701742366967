import { observer } from "mobx-react";
import { ContentSqaure } from "../../UI/ContentSqaure";
import userStore from "../../../store/userStore";
import {ReactComponent as Smiley} from "../../../assets/smiley.svg";

const Done: React.FC = () => {
  const { updateAmount } = userStore
    return (
        <ContentSqaure>
            <div>
                <h3 style={{ width: "40%", margin: "auto", direction: "rtl", marginBottom: "1rem", color: "#49454F"}}>תודה רבה {updateAmount} המנויים עודכנו בהצלחה!</h3>
                <Smiley />
                <p style={{fontWeight: 700, color: "#49454F" }}>חידוש המנויים יהיה זמין בעוד 48 שעות</p>
            </div>
        </ContentSqaure>
    );
};

const DoneObserver = observer(Done)
export default DoneObserver;