import { IUser } from "../UserData";
import styles from "./SubscriptionSquare.module.scss";
interface IProps {
    subscription: IUser;
    index?: number;
}

export const SubscriptionSquare: React.FC<IProps> = ({ index, subscription }) => {
    return (
        <div className={styles["subscription-list-item__data-container"]}>
            {index !== undefined &&<div> <h3 className={styles["subscription-list-item__count"]}>מנוי מספר {index + 1}</h3></div>}
            <div className={styles["subscription-list-item__content"]}>
            <div className={styles["subscription-list-item__data"]}>
                <div className={styles["subscription-list-item__data-value"]}>{subscription.oldFirstName}</div>
                <div className={styles["subscription-list-item__data-value"]}>{subscription.oldLastName}</div>
                <div className={styles["subscription-list-item__data-value"]}>{subscription.clientNumber}</div>
                <div className={styles["subscription-list-item__data-value"]}>{`איזור ${subscription.area}, שורה ${subscription.row}, כיסא ${subscription.seat}`}</div>
            </div>
            <div className={styles["subscription-list-item__data"]}>
                <div className={styles["subscription-list-item__data-param"]}>שם פרטי</div>
                <div className={styles["subscription-list-item__data-param"]}>שם משפחה</div>
                <div className={styles["subscription-list-item__data-param"]}>מספר לקוח</div>
                <div className={styles["subscription-list-item__data-param"]}>מיקום</div>
            </div>
            </div>
        </div>
    );
};
